<template>
  <div class="love-life" />
</template>

<script>
export default {

}
</script>

<style lang="scss">
  .love-life {
    position: absolute;
    width: 100%;
    height: 100%;
    background: url('../assets/images/hpbd.jpg') no-repeat center center;
  }
</style>
